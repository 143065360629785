import React from 'react';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';
import { IconRoundBackground } from './H1';

const MDiv = styled.div`
  position: fixed;
  top: 32px;
  left: 16px;
  padding: 0;
  z-index: 1001;
  width: calc(100% - 32px);
  max-height: calc(100% - 64px);
  background-color: ${COLORS.classic};
  border-radius: ${commonStyle.borderRadius}px;
  overscroll-behavior: none;
  overflow: scroll;
`;

const Opacity = styled.div`
  background-color: ${COLORS.disabled};
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.7;
`;

const Modal = ({ visible, children, handleCancel, style }) => {
  return ReactDOM.createPortal(
    <AnimatePresence>
      {visible && (
        <motion.div
          key='home'
          transition={{ duration: 0.3 }}
          initial={{ opacity: 0.1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.1 }}
        >
          <Opacity onClick={handleCancel} />
          <MDiv visible={visible} style={style}>
            <IconRoundBackground
              onClick={handleCancel}
              style={{
                position: 'fixed',
                zIndex: 11,
                right: 32,
                top: 48,
                backgroundColor: COLORS.classic,
              }}
            >
              X
            </IconRoundBackground>
            {children}
          </MDiv>
        </motion.div>
      )}
    </AnimatePresence>,
    document.getElementById('modal')
  );
};

export { Modal };
