import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../config';

const loading = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1,2.2);
  }
  40% {
    transform: scale(1);
  }
`;

const LoadingBar = styled.div`
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: ${loading} 1s ease-in-out infinite;
  &:nth-child(1) {
    background-color: ${COLORS.primary};
    animation-delay: 0;
  }
  &:nth-child(2) {
    background-color: ${COLORS.secondary};
    animation-delay: 0.09s;
  }
  &:nth-child(3) {
    background-color: ${COLORS.primary};
    animation-delay: 0.18s;
  }
  &:nth-child(4) {
    background-color: ${COLORS.secondary};
    animation-delay: 0.27s;
  }
`;

const RefreshDiv = styled(motion.div)`
  z-index: 1;
  pointer-events: none;
  --refresh-width: 55px;
  background: #fff;
  width: var(--refresh-width);
  height: var(--refresh-width);
  border-radius: 50%;
  position: absolute;
  left: calc(50% - var(--refresh-width) / 2);
  padding: 8px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: all 300ms cubic-bezier(0, 0, 0.2, 1);
  will-change: transform, opacity;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Refresher = ({ active }) => {
  return (
    <AnimatePresence>
      {active && (
        <RefreshDiv
          key='refresher'
          initial={{ y: '-100px', visibility: 'hidden' }}
          animate={{ y: '48px', visibility: 'visible' }}
          exit={{ y: '-100px', visibility: 'hidden' }}
        >
          <LoadingBar></LoadingBar>
          <LoadingBar></LoadingBar>
          <LoadingBar></LoadingBar>
          <LoadingBar></LoadingBar>
        </RefreshDiv>
      )}
    </AnimatePresence>
  );
};

export { Refresher };
