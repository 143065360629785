import React from 'react';
import styled from 'styled-components';
import { WrapperCard } from '.';

const MP = styled.div`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  font-size: 16px;
`;

export const ResidenceCard = ({
  img = '/images/building_background.png',
  program,
  reference,
  id,
}) => {
  return (
    <WrapperCard to={'/' + id} style={{ marginBottom: 28 }}>
      <img
        src={img}
        style={{
          margin: 4,
          width: 58,
          height: 58,
          borderRadius: 58 / 2,
          objectFit: 'cover',
        }}
        alt='IMG'
      />
      <div
        style={{
          flex: 1,
          display: 'flex',
          marginLeft: 8,
          justifyContent: 'space-between',
        }}
      >
        <div>
          <MP bold>{program?.name}</MP>
          <MP bold>
            {program?.city || "Ville Inconnue"} -{' '}
            <span style={{ fontWeight: 400 }}>{program?.postalcode || 'XX'}</span>
          </MP>
          <MP style={{ fontSize: 14 }}>Lot {reference}</MP>
        </div>
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: 8,
          }}
        >
          <span
            className='icon-dbi-pic-_selectionner'
            style={{ fontSize: 18, marginRight: 8 }}
          ></span>
        </div>
      </div>
    </WrapperCard>
  );
};
