import React from 'react';
import { COLORS } from '../config';
import { IconRoundBackground } from './H1';
import { WrapperCard } from './WrapperCard';

const ContactCard = ({
  style,
  contact = {
    name: 'M. Mac Donalds',
    phone: '01 23 45 67 89',
    email: 'macdeonalds@dbi.com',
  },
  hideTitle,
}) => {
  return (
    <WrapperCard style={{ ...style, justifyContent: 'space-between' }} shadow>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 4,
        }}
      >
        <span
          className={`icon-dbi-pic-_menu-contact`}
          style={{
            fontSize: 28,
            fontWeight: 700,
            marginLeft: 4,
          }}
        />
        <div style={{ marginLeft: 16 }}>
          {hideTitle ? null : (
            <div style={{ color: COLORS.secondary, fontWeight: 800 }}>
                Service SAV
            </div>
          )}
          <div style={{ fontWeight: 800 }}>{contact.name}</div>
          <div>{contact.phone}</div>
          <div>{contact.email}</div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: 8,
        }}
      >
        <a
          href={`tel:${contact.phone}`}
          style={{ textDecoration: 'none', color: COLORS.primary }}
        >
          <IconRoundBackground style={{ marginBottom: 8 }}>
            <span
              className={`icon-dbi-pic-_telephoner`}
              style={{
                fontSize: 20,
                backgroundColor: COLORS.classic,
                borderRadius: 12,
              }}
            />
          </IconRoundBackground>
        </a>
        <a
          href={`mailto:${contact.email}`}
          style={{ textDecoration: 'none', color: COLORS.primary }}
        >
          <IconRoundBackground>
            <span
              className={`icon-dbi-pic-_Message`}
              style={{
                fontSize: 14,
                backgroundColor: COLORS.classic,
                borderRadius: 12,
              }}
            />
          </IconRoundBackground>
        </a>
      </div>
    </WrapperCard>
  );
};

export { ContactCard };
