import React from 'react';
import { DocumentCard, H4, PageHeader } from '../Components';

const Documents = ({ lot }) => {
  return (
    <>
      <PageHeader
        title={'Vos documents'}
        /* subtitle={
          'Nec et nisl nec leo laoreet laoreet at eu mi. Donec scelerisque eleifend massa, quis hendrerit orci malesuada eget. Sed laoreet metus nec nibh luctus.'
        }*/
      />
      <br />
      <H4 style={{ margin: 8 }}>Vos contrats</H4>
      {lot?.documents.filter((doc) => doc.objectsTypeId === 5).length === 0 && (
        <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic' }}>
          Aucun contrat n'est actuellement disponible.
        </p>
      )}
      {lot?.documents
        .filter((doc) => doc.objectsTypeId === 5)
        .map((doc) => (
          <DocumentCard
            style={{ marginBottom: 8 }}
            key={doc.name}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        ))}
      <br />
      <H4 style={{ margin: 8, marginTop: 16 }}>Vos demandes</H4>
      {lot?.documents.filter((doc) => [4, 8, 7].includes(doc.objectsTypeId))
        .length === 0 && (
        <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic' }}>
          Aucune demande n'est actuellement formulée.
        </p>
      )}
      {lot?.documents
        .filter((doc) => [4, 8, 7].includes(doc.objectsTypeId))
        .map((doc) => (
          <DocumentCard
            style={{ marginBottom: 8 }}
            key={doc.name}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        ))}
        <br />
        <H4 style={{ margin: 8, marginTop: 16 }}>Plaquettes programme</H4>
        {lot?.documents.filter((doc) => [14].includes(doc.objectsTypeId))
          .length === 0 && (
          <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic' }}>
            Aucune plaquette n'est actuellement disponible.
          </p>
        )}
        {lot?.documents
          .filter((doc) => [14].includes(doc.objectsTypeId))
          .map((doc) => (
            <DocumentCard
              style={{ marginBottom: 8 }}
              key={doc.name}
              lotId={lot?.id}
              programId={lot?.program?.id}
              title={doc.name}
              subtitle={doc.description}
              filename={doc.filename}
            />
          ))}
          <br />
          <H4 style={{ margin: 8, marginTop: 16 }}>Prestations</H4>
          {lot?.documents.filter((doc) => [3, 6].includes(doc.objectsTypeId))
            .length === 0 && (
            <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic' }}>
             Aucune prestation n'est actuellement proposée.
            </p>
          )}
          {lot?.documents
            .filter((doc) => [3, 6].includes(doc.objectsTypeId))
            .map((doc) => (
              <DocumentCard
                style={{ marginBottom: 8 }}
                key={doc.name}
                lotId={lot?.id}
                programId={lot?.program?.id}
                title={doc.name}
                subtitle={doc.description}
                filename={doc.filename}
              />
            ))}
            <br />
            <H4 style={{ margin: 8, marginTop: 16 }}>Autres</H4>
            {lot?.documents.filter((doc) => [13].includes(doc.objectsTypeId))
              .length === 0 && (
              <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic' }}>
               Aucun autre document n'est actuellement disponible.
              </p>
            )}
            {lot?.documents
              .filter((doc) => [13].includes(doc.objectsTypeId))
              .map((doc) => (
                <DocumentCard
                  style={{ marginBottom: 8 }}
                  key={doc.name}
                  lotId={lot?.id}
                  programId={lot?.program?.id}
                  title={doc.name}
                  subtitle={doc.description}
                  filename={doc.filename}
                />
              ))}
        {/* <H4 style={{ margin: 8, marginTop: 16 }}>Autres documents :</H4>
      {lot?.documents.filter((doc) => [11].includes(doc.objectsTypeId))
        .length === 0 && (
        <p style={{ marginLeft: 8, fontStyle: 'italic' }}>
          Aucune documentation à afficher pour le moment.
        </p>
      )}
      {lot?.documents
        .filter((doc) => [11].includes(doc.objectsTypeId))
        .map((doc, i) => (
          <DocumentCard
            style={{ marginBottom: 8 }}
            key={doc.name  +  i}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        )) */}
    </>
  );
};

export { Documents };
