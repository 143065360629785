import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  DocumentCard,
  H4,
  PageHeader,
  Row,
  WrapperCard,
} from '../Components';

const Livraison = ({ lot }) => {
  let { residenceId } = useParams();

  return (
    <>
      <PageHeader title={'Livraison de votre bien'} />
      <H4 style={{ margin: '16px 8px 8px 8px' }}>Votre rendez-vous :</H4>
      {lot?.deliveryDate ? (
        <WrapperCard
          to={`/meeting/${residenceId}`}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 8,
          }}
        >
          <div>
            <H4 style={{ marginBottom: 8 }}>Remise de vos clés !</H4>
            <Row style={{ alignItems: 'center', marginBottom: 8 }}>
              <span
                className='icon-dbi-pic-_Rdv'
                style={{ fontSize: 20, marginRight: 8 }}
              />
              <span style={{ fontSize: 12, paddingRight: 16 }}>
                Ce rendez-vous se déroulera le xx/xx/xx à 11h30 Au 25 rue de
                Lorem Ipsum 54 856 Dolors sit amet
              </span>
            </Row>
          </div>
          <span
            className='icon-dbi-pic-_selectionner'
            style={{ fontSize: 20 }}
          />
        </WrapperCard>
      ) : (
        <p style={{ fontStyle: 'italic', fontSize: 12, marginLeft: 8 }}>
          Vous verrez votre rendez-vous ici quand celui-ci sera programmé.
        </p>
      )}
      <H4 style={{ margin: '16px 8px 8px 8px' }}>Documents utiles :</H4>
      <DocumentCard
        title={'Préparation à la livraison'}
        subtitle='Consultez la préparation à la livraison pour tout savoir sur la remise de vos clefs.'
        url='/test/test-generated.pdf'
      ></DocumentCard>
    </>
  );
};

export { Livraison };
