import React from 'react';
import { menus } from '../config';
import { COLORS, commonStyle } from '../config';
import { WrapperCard } from '.';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button } from './Button';

const MenuCardDesktop = ({ menu, disabled, active }) => {

  const { residenceId } = useParams();

  return (
    <WrapperCard
      to={disabled ? undefined : menu.link ? menu.link + residenceId : '/'}
      style={{
        margin: '8px 4px',
        padding: '6px 8px',
        height: 30,
        opacity: disabled ? 0.6 : 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: active ? COLORS.primary : undefined,
      }}
    >
      <div
        style={{
          fontSize: 12,
          fontWeight: 700,
          color: active ? COLORS.classic : undefined,
        }}
      >
        {menu.title}
      </div>
      <div
        style={{
          width: 40,
          backgroundColor: disabled ? COLORS.disabled : 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: commonStyle.borderRadius,
        }}
      >
        <span
          className={`icon-dbi-pic-${menu.icon}`}
          style={{ fontSize: 24, color: active ? COLORS.secondary : undefined }}
        />
      </div>
    </WrapperCard>
  );
};

const DesktopMenu = ({ lots, lot, disconnect }) => {
  let { path } = useRouteMatch();
  const history = useHistory();

  return (
    <div className='desktopMenu'>
      <img
        src='/images/logoFull-Dark.png'
        alt='Logo DBI'
        width={'auto'}
        height={40}
        style={{ marginLeft: 8 }}
      />
      <MenuCardDesktop
        menu={{ title: 'Accueil', link: '/' }}
        active={path === '/:residenceId'}
      />
      {menus.map((menu, i) => (
        <MenuCardDesktop
          key={'menu_item_card_' + i}
          menu={menu}
          disabled={
            (menu.link === '/livraison/' &&
            !lot?.program?.workflow?.find((wrk) => wrk.workflowTypeId >= 11)) || (menu.link === '/copro/' &&
                  !lot?.program?.syndicate?.name) || (menu.link === '/tma/' && lot?.program?.id === 300)
          }
          active={path.search(menu.link) !== -1}
        />
      ))}
      {lots?.length > 1 && (
        <MenuCardDesktop
          menu={{ title: 'Choix du bien', icon: '_menu-choix-bien', link: '' }}
        />
      )}
      <Button
        style={{
          padding: '8px 32px',
          marginTop: 16,
          marginBottom: 32,
          marginLeft: 6,
        }}
        onClick={() => {
          disconnect();
          history.push('/');
        }}
      >
        <span
          className={`icon-dbi-pic-_Disconnect`}
          style={{
            fontSize: 24,
            marginRight: 16,
          }}
        />
        Déconnexion
      </Button>
    </div>
  )
};

export { DesktopMenu };
