import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { PageMenuWrapper } from '../Components';
import {
  Acceuil,
  BienImmo,
  Prestations,
  NotFound,
  MenuPage,
  TMA,
  Travaux,
  Journal,
  Meeting,
  SAV,
  Documents,
  Compte,
  Livraison,
  Copro,
  Contacts,
  Rdv,
  Messagerie,
  MentionsLegales,
  Logout
} from '.';
import { AppelFonds } from './AppelFonds';

const menuVariants = {
  initial: {
    y: '-100vh',
  },
  in: {
    y: '0',
  },
  out: {
    y: '-100vh',
  },
};

const pageVariants = {
  initial: {
    x: '100vw',
  },
  in: {
    x: '0',
  },
  out: {
    x: '100vw',
  },
};
const homeVariants = {
  initial: {
    x: '-100vw',
  },
  in: {
    x: '0',
  },
  out: {
    x: '-100vw',
  },
};

const StateWrapper = ({ lots, children, updateLot, refresh }) => {
  const [selectedLot, setSelectedLot] = useState(null);
  const { residenceId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!lots || lots.length < 1) {
      history.replace('/');
      return;
    }
    const foundLot = lots.find((lot) => String(lot.id) === String(residenceId));
    if (!foundLot) history.replace('/');
    setSelectedLot(foundLot);
  }, [residenceId, lots]);

  return React.cloneElement(children, {
    lot: selectedLot,
    updateLot: updateLot,
    refresh: refresh,
  });
};

const RouteurAcceuil = ({
  user,
  lots,
  updateLot,
  updateUser,
  refresh,
  disconnect,
}) => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <Route exact path={'/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='home'
                transition={{ duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={homeVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Acceuil user={user} />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/programme/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='programme'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <BienImmo />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/prestations/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='prestations'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Prestations />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/tma/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='tma'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <TMA />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/travaux/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='travaux'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Travaux />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/journal/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='journal'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Journal />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/meeting/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='meeting'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Meeting />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/sav/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='sav'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <SAV />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/appelsfonds/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='appelsfonds'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <AppelFonds />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/documents/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='documents'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Documents />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/compte/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='compte'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <Compte user={user} updateUser={updateUser} disconnect={disconnect} />
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/livraison/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='livraison'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Livraison />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/copro/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='copro'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Copro />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/contacts/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='contact'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Contacts />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
		<Route path={'/rdv/:residenceId'}>
		  <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
			<PageMenuWrapper disconnect={disconnect} lots={lots}>
			  <motion.div
				key='rdv'
				transition={{ ease: 'easeOut', duration: 0.2 }}
				initial='initial'
				animate='in'
				exit='out'
				variants={pageVariants}
			  >
				<StateWrapper
				  lots={lots}
				  updateLot={updateLot}
				  refresh={refresh}
				>
				  <Rdv />
				</StateWrapper>
			  </motion.div>
			</PageMenuWrapper>
		  </StateWrapper>
		</Route>
        <Route path={'/messaging/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='messaging'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <Messagerie />
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path='/mentionslegales/:residenceId' element={MentionsLegales}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='mentionslegales'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <MentionsLegales />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path='/logout/:residenceId' element={Logout}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='logout'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <Logout />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route path={'/menu/:residenceId'}>
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='menu'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={menuVariants}
              >
                <StateWrapper
                  lots={lots}
                  updateLot={updateLot}
                  refresh={refresh}
                >
                  <MenuPage lots={lots} />
                </StateWrapper>
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
        <Route exact path={'*/:residenceId'}>
          {/*** Route de fallback si erreur de lien, permet d'avoir un menu pour se rediriger, plutôt qu'une page blanche */}
          <StateWrapper lots={lots} updateLot={updateLot} refresh={refresh}>
            <PageMenuWrapper disconnect={disconnect} lots={lots}>
              <motion.div
                key='notfound'
                transition={{ ease: 'easeOut', duration: 0.2 }}
                initial='initial'
                animate='in'
                exit='out'
                variants={pageVariants}
              >
                <NotFound />
              </motion.div>
            </PageMenuWrapper>
          </StateWrapper>
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

export { RouteurAcceuil };
