import React from 'react';
import { fileGet } from '../API';
import {
  DocumentCard,
  LI,
  Notifications,
  PageHeader,
  UL,
  WrapperCard,
  TravauxCard,
} from '../Components';
import { COLORS } from '../config';

const BienImmo = ({ lot }) => {
  const [plan, setPlan] = React.useState(null);

  React.useEffect(() => {
    syncImages();
  }, [lot]);

  const syncImages = async () => {
    if (!lot) return;
    const planName = lot?.documents?.filter(
      (doc) => doc.objectsTypeId === 9
    )?.[0]?.filename;
    if (planName) {
      const doc = await fileGet({
        lotId: lot?.id,
        programId: lot?.program.id,
        filename: planName,
        download: false,
      });

      setPlan(doc.data);
    }
  };

  return (
    <>
      <PageHeader title={'Votre bien'} />
      <WrapperCard
        style={{
          justifiContent: 'space-between',
          overflow: 'hidden',
          marginTop: 32,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingLeft: 8,
            flex: 1,
          }}
        >
          <h4 style={{ marginBottom: 0, marginTop: 0 }}>
              {lot?.program?.address + "(" + (lot?.program?.city +", "+lot?.program?.postalcode||"XXNNN") + ")"}
          </h4>
          <span style={{ fontWeight: 300 }}>{lot?.program?.name}</span>
        </div>
        <img
          style={{
            margin: 0,
            objectFit: 'cover',
            marginRight: -18,
            marginTop: -12,
            marginBottom: -17,
          }}
          src={lot?.program?.visuel}
          alt='imageLot'
          width={200}
          height={110}
        />
      </WrapperCard>
      <WrapperCard style={{ marginTop: 24 }} row={false}>
        <h4 style={{ margin: '0 8px 8px 8px' }}>Lot {lot?.reference}</h4>
        <div
          style={{
            borderTop: `1px solid ${COLORS.disabled}`,
            display: 'flex',
          }}
        >
          <UL style={{ flex: 1 }}>
            <LI>Appartement {lot?.typology}</LI>
            <LI>{lot?.etage === 0 ? 'Étage : RDC' : lot?.etage === 1 ? 'Étage : 1er' : lot?.etage > 1 ? `Étage : ${lot?.etage}ème` : ''}
</LI>
          </UL>
          <UL style={{ flex: 1, borderLeft: `1px solid ${COLORS.disabled}` }}>
            <LI style={{ lineHeight: 1.6 }}>
              Surface : {lot?.surface_habitable}m<sup>2</sup>
            </LI>
            {lot?.surface_terrasse > 0 && (
              <LI style={{ lineHeight: 1.6 }}>
                Surface terrasse : {lot?.surface_terrasse}m<sup>2</sup>
              </LI>
            )}
            {lot?.surface_jardin > 0 && (
              <LI style={{ lineHeight: 1.6 }}>
                Surface jardin : {lot?.surface_jardin}m<sup>2</sup>
              </LI>
            )}
            {lot?.annexes &&
              lot?.annexes.length > 0 &&
              lot?.annexes.map((annex, i) => (
                <LI key={'annexe_' + i} style={{ lineHeight: 1.6 }}>
                  {annex.type} :{' '}
                  {annex.area ? (
                    <span>
                      {annex.area + ' m'}
                      <sup>2</sup>
                      {' - '}
                    </span>
                  ) : (
                    ''
                  )}
                  <i>{annex.priceTTC ? annex.priceTTC + '€ (TTC)' : 'N/C'}</i>
                </LI>
              ))}
          </UL>
        </div>
        <div>
          <table style={{ fontSize: 12, width: '100%' }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: '50%',
                    padding: '4px 16px',
                    backgroundColor: COLORS.background,
                  }}
                >
                  Livraison prévisionnelle
                </td>
                <td
                  style={{
                    width: '50%',
                    padding: '4px 16px',
                    backgroundColor: COLORS.primary,
                    color: COLORS.classic,
                    fontWeight: 800,
                  }}
                >
                  {lot?.program?.deliveryDate  ? new Date(lot?.program?.deliveryDate).toLocaleDateString() : ""}
                  {/**
                   * FIXME
                   * Attention, si String type 11/12/2020, le localeDateString va
                   * renverser pour le FR, 12/11/2020
                   * **/}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '50%',
                    padding: '4px 16px',
                    backgroundColor: COLORS.background,
                  }}
                >
                  Prix total
                </td>
                <td
                  style={{
                    width: '50%',
                    padding: '4px 16px',
                    backgroundColor: COLORS.primary,
                    color: COLORS.classic,
                    fontWeight: 800,
                  }}
                >
                  {Number(lot?.reservationTTC || 0).toLocaleString()} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
          {/*   <img
          src={plan}
          style={{ objectFit: 'contain', height: 'auto', width: '100%' }}
          alt='plan_residence'
        />  */}
      </WrapperCard>
      {/* <TravauxCard /> */}
      <Notifications lot={lot} />
        {/*
      {lot?.documents.filter((doc) => doc.objectsTypeId === 5).length > 0 && (
        <h4 style={{ margin: '32px 8px 8px 16px' }}>Vos documents :</h4>
      )}
      {lot?.documents
        .filter((doc) => doc.objectsTypeId === 5)
        .map((doc) => (
          <DocumentCard
            key={doc.name}
            lotId={lot?.id}
            programId={lot?.program?.id}
            title={doc.name}
            subtitle={doc.description}
            filename={doc.filename}
          />
        ))}
        */}
    </>
  );
};

export { BienImmo };
