import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../config';
import { Button, WrapperCard, Subtitle } from '.';

const Alert = styled.div`
  font-size: 12px;
  color: ${COLORS.secondary};
`;

const TravauxWorkflowCard = ({
  title,
  alert,
  subtitle,
  buttonAlert,
  button = { title: 'Titre', link: '/' },
  active,
  over,
  ...props
}) => {
  return (
    <div {...props}>
      <WrapperCard row={false} style={{ padding: '16px 32px' }} shadow={active}>
        <h4
          style={{
            margin: 0,
            filter: `${active ? 'opacity(1)' : 'opacity(0.5)'}`,
          }}
        >
          {title}
        </h4>
        <Alert>{alert}</Alert>
        <Subtitle style={{ marginTop: 2 }} over={over}>
          {subtitle}
        </Subtitle>
        {buttonAlert && (
          <Button
            secondary
            disabled={!active}
            as={Link}
            to={buttonAlert.link}
            style={{
              marginTop: 8,
              padding: '12px 32px',
              width: 'max-content',
              fontSize: 16,
            }}
          >
            {buttonAlert.title}
          </Button>
        )}
        {button && (
          <Button
            primary
            disabled={!active}
            as={Link}
            to={button.link}
            style={{
              marginTop: 8,
              padding: '12px 32px',
              width: 'max-content',
              fontSize: 16,
            }}
          >
            {button.title}
          </Button>
        )}
      </WrapperCard>
    </div>
  );
};

export { TravauxWorkflowCard };
