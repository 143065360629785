import React from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';
import { Residences } from '.';
import { RouteurAcceuil } from './RouteurAcceuil';

export const Home = ({
  user,
  disconnect,
  lots,
  updateLot,
  updateUser,
  refresh,
}) => {
  return (
    <HashRouter>
      <Switch>
        <Route path='/:residenceId'>
          <RouteurAcceuil
            user={user}
            lots={lots}
            updateLot={updateLot}
            updateUser={updateUser}
            refresh={refresh}
            disconnect={disconnect}
          />
        </Route>
        <Route path='/'>
          <Residences disconnect={disconnect} user={user} lots={lots} />
        </Route>
      </Switch>
    </HashRouter>
  );
};
