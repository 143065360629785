import React from 'react';
import { useParams } from 'react-router-dom';
import { DocumentCard, PageHeader, UploadZone, ArkaCard } from '../Components';

const Prestations = ({ lot, refresh }) => {
  let { residenceId } = useParams();
  const [localDocuments, setLocalDocuments] = React.useState([]);
  const [uploadDocuments, setUploadDocuments] = React.useState([]);

  React.useEffect(() => {
    if (!lot || !lot.documents) return;
    const uploadDocs = lot.documents.filter((doc) => doc.objectsTypeId === 6);
    const restDocs = lot.documents.filter((doc) => doc.objectsTypeId === 3);
    setLocalDocuments(restDocs);
    setUploadDocuments(uploadDocs);
  }, [lot]);

  /*const url = "https://demathieu-bard.dev.configurateur-arka.com/?token=" + localStorage.getItem("token");*/
  const url = "https://demathieu-bard.dev.configurateur-arka.com/reservations/" + lot?.reservationId +"/configurateur-deco?token=" + localStorage.getItem("token");

  return (
    <>

      <PageHeader title={'Choix des prestations'} />
      {/** FIXME : Static number of catalogue de prestations ? Or dynamic ? */}
       {localDocuments.map((catalogue, i) => (
        <DocumentCard
          key={'prestations_document_' + i}
          style={{ marginTop: 16 }}
          title={catalogue.name}
          filename={catalogue.filename}
          programId={lot?.program?.id}
          subtitle={
            'Téléchargez le catalogue et choisissez les prestations de votre futur bien.'
          }
        />
      ))}
      {localDocuments.length === 0 && (
        <p style={{ fontSize: 12, marginLeft: 8, fontStyle: 'italic'}}>Aucun document pour le moment.</p>
      )}
        <>
          <h4 style={{ marginLeft: 8 }}>
            Une fois vos documents remplis, envoyez les :
          </h4>
          <form encType='multipart/form-data'>
            <UploadZone
              style={{ margin: 8 }}
              lot={lot}
              typeId={6}
              refresh={refresh}
            />
          </form>
        </>
      {uploadDocuments.length > 0 && (
        <h4 style={{ marginLeft: 8 }}>Vos documents déjà envoyés :</h4>
      )}
      {uploadDocuments.map((doc) => (
        <DocumentCard
          key={doc.name}
          style={{ marginTop: 16 }}
          title={doc.name}
          filename={doc.filename}
          lotId={lot?.id}
          programId={lot?.program?.id}
          subtitle={new Date(doc.send_at).toLocaleDateString()}
        />
      ))}
      {lot?.program?.Arka === true && (
          <h4 style={{ marginLeft: 8 }}>Configurez votre appartement :</h4>
      )}
      {lot?.program?.Arka === true && (
          <ArkaCard
              key={"Configurateur"}
              style={{ marginTop: 16, backgroundColor:'rgb(224, 76, 56)' }}
              title={"Appartement 3D"}
              url={url}
              lotId={lot?.id}
              programId={lot?.program?.id}
          />
      )}
    </>
  );
};

export { Prestations };