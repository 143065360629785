import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useParams } from 'react-router-dom';
import { COLORS } from '../config';
import { WrapperCard } from './WrapperCard';
import { getNotifications } from '../Helpers/NotifsAcceuil';

const allNotifs = [
  {
    id: 1,
    title: 'Choix des prestations',
    activeSubtitle: 'Il est temps que vous choisissiez votre intérieur.',
    subtitle: 'Vous nous avez indiqué vos choix.',
    icon: '',
    active: true,
    visible: true,
    link: '/prestations/',
  },
  {
    id: 2,
    title: 'Appels de fonds',
    activeSubtitle: 'Une nouvelle échéance approche.',
    subtitle: 'Appels de fonds réglés.', //FIXME : unknown text
    icon: '',
    active: true,
    visible: true,
    link: '/appelsfonds/',
  },
  {
    id: 3,
    title: 'Vos modifications',
    activeSubtitle: 'Il est temps de demander vos modifications', //FIXME : unknown text
    subtitle:
      "l'avancement des travaux ne permet plus de prendre en compte les modifications de plan",
    icon: '',
    active: false,
    visible: true,
    link: '/tma/',
  },
  {
    id: 4,
    title: 'Félicitations',
    activeSubtitle: "Il est temps d'aller voir votre bien !", //FIXME : unknown text
    subtitle: 'Vous avez réservé votre appartement !',
    icon: 'keys',
    active: false,
    visible: false,
    link: '/livraison/',
  },
  {
    id: 5,
    title: 'Visite de cloisons achevées', //FIXME : Faute d'orthographe + pas logique avec le texte...
    activeSubtitle: 'Vous devez choisir votre date de visite',
    subtitle: 'Unknown possible text', //FIXME : inactive text unknown
    icon: 'work',
    active: true,
    visible: false,
    link: '/meeting/',
  },
];

const variantsAnim = {
  visible: (i) => ({
    x: 0,
    transition: {
      delay: i * 0.2,
      ease: 'easeInOut',
      duration: 0.5,
      when: 'afterChildren',
    },
  }),
  hidden: { x: '-100vw' },
};

const Notifications = ({ lot }) => {
  let { residenceId } = useParams();
  const [localNotifications, setLocalNotifications] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    if (!notifications) return;
    let tmp = notifications.map((notif) => {
      let tmpNotif = allNotifs.find((not) => not.id === notif.id);
      if (tmpNotif) {
        tmpNotif.visible = true;
        tmpNotif.active = notif.active;
        return tmpNotif;
      } else return null;
    });
    setLocalNotifications(tmp);
  }, [notifications]);

  React.useEffect(() => {
    if (lot)
      setNotifications(
        getNotifications({ appel: lot.appel, program: lot.program, lot })
      );
  }, [lot]);

  return (
    <>
      {localNotifications.length > 0 && (
        <h4 style={{ margin: '32px 8px 0px 16px' }}>Vos notifications :</h4>
      )}
      <AnimatePresence>
        {localNotifications.map((notif, i) =>
          notif?.visible ? (
            <motion.div
              key={'wrapper_notifications_' + i}
              custom={i}
              animate={'visible'}
              initial='hidden'
              exit={'hidden'}
              variants={variantsAnim}
            >
              <WrapperCard
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: notif.active
                    ? COLORS.secondary
                    : COLORS.classic,
                  color: notif.active ? COLORS.classic : null,
                  minHeight: 54,
                  alignItems: 'center',
                  marginTop: 8,
                }}
                to={notif.link + residenceId}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      flex: '0 0 40px',
                      margin: 8,
                      width: 45,
                      overflow: 'hidden',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      className='icon-dbi-pic-'
                      style={{ fontSize: 28, fontWeight: 800 }}
                    >
                      {notif.icon}
                    </span>
                  </div>
                  <div style={{ lineHeight: 1, marginRight: 16 }}>
                    <span style={{ fontWeight: 800 }}>{notif.title}</span>
                    <br />
                    <span
                      style={{
                        color: notif.active ? COLORS.classic : COLORS.secondary,
                        fontSize: 12,
                      }}
                    >
                      {notif.active ? notif.activeSubtitle : notif.subtitle}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <span
                    className='icon-dbi-pic-_selectionner'
                    style={{ fontSize: 20 }}
                  ></span>
                </div>
              </WrapperCard>
            </motion.div>
          ) : null
        )}
      </AnimatePresence>
    </>
  );
};

export { Notifications };
