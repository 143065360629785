import React from 'react';
import { H4, PageHeader } from '../Components';
import { COLORS, commonStyle } from '../config';

const Messagerie = ({ lot }) => {
  return (
    <>
      <PageHeader
        title={'Messagerie'}
        subtitle={
          'Cras sagittis. Vestibulum purus quam, scelerisque ut, mollis sed, nonummy id, metus. Phasellus dolor. Vestibulum dapibus nunc ac augue. Vestibulum rutrum, mi nec elementum vehicula, eros quam gravida nisl, id fringilla neque ante vel mi.'
        }
      />
      <div
        style={{
          backgroundColor: COLORS.classic,
          height: 400,
          borderRadius: commonStyle.borderRadius,
        }}
      ></div>
    </>
  );
};

export { Messagerie };
