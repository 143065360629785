import React from 'react';
import { MenuCard } from '../Components';
import { menus } from '../config';

const MenuPage = ({ lots, lot }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-around',
      }}
    >
      {menus.map((menu, i) => (
        <MenuCard
          key={'menu_item_card_' + i}
          menu={menu}
          disabled={
            (menu.link === '/livraison/' &&
            !lot?.program?.workflow?.find((wrk) => wrk.workflowTypeId >= 11)) || (menu.link === '/tma/' &&
                  lot?.program?.id === 300)
          }
        />
      ))}
      {lots?.length > 1 && (
        <MenuCard
          menu={{ title: 'Choix du bien', icon: '_menu-choix-bien', link: '' }}
        />
      )}
    </div>
  );
};

export { MenuPage };
