import { createContainer, Notification } from './Notification';
import { NotificationsManager } from './NotificationsManager';
import ReactDOM from 'react-dom';

const containerElement = createContainer();
let notify;

ReactDOM.render(
  <NotificationsManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />,
  containerElement
);
export { Notification };

function info(children, duration) {
  return notify({
    type: 'info',
    children,
    duration,
  });
}

function success(children, duration) {
  return notify({
    type: 'success',
    children,
    duration,
  });
}

function warning(children, duration) {
  return notify({
    type: 'warning',
    children,
    duration,
  });
}

function error(children, duration) {
  return notify({
    type: 'error',
    children,
    duration,
  });
}

export const message = {
  info,
  success,
  warning,
  error,
};
