import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src='/images/work.png'
        width={300}
        height={'auto'}
        alt='Page non trouvée'
      />
    </div>
  );
};

export { NotFound };
