import React from 'react';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';

const StyledButton = styled.button.attrs((props) => ({
  disabled: props.loading || props.disabled,
}))`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${commonStyle.borderRadius * 3}px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  padding: 12px 32px;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.primary
      ? COLORS.primary
      : props.secondary
      ? COLORS.secondary
      : props.disabled
      ? COLORS.disabled
      : COLORS.classic};
  filter: ${(props) =>
    props.disabled ? 'opacity(var(--value, 50%))' : 'none'};
  color: ${(props) =>
    props.primary || props.secondary ? COLORS.classic : 'black'};
  font-weight: 800;
  text-decoration: none;
  transition: background-color 200ms ease-in-out, width 300ms ease-in-out;
  :active {
    background-color: ${COLORS.disabled};
  }
`;

const Loader = styled.div`
    font-size: 2px;
    margin: 0px 8px 0px -8px;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: ${(props) =>
      props.primary ? COLORS.seconday : COLORS.secondary};
    background: linear-gradient(
      to right,
      ${(props) => (props.primary ? COLORS.seconday : COLORS.secondary)} 10%,
      rgba(25, 159, 76, 0) 42%
    );
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
  }
  &:before {
    width: 50%;
    height: 50%;
    background-color: ${(props) =>
      props.primary
        ? COLORS.primary
        : props.secondary
        ? COLORS.secondary
        : props.disabled
        ? COLORS.disabled
        : COLORS.classic};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background-color: ${(props) =>
      props.primary
        ? COLORS.primary
        : props.secondary
        ? COLORS.secondary
        : props.disabled
        ? COLORS.disabled
        : COLORS.classic};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
`;

const Button = ({
  children,
  loading,
  disabled,
  primary,
  secondary,
  to,
  as,
  style,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      as={disabled ? undefined : as}
      to={disabled ? undefined : to}
      primary={primary ? 1 : 0}
      secondary={secondary ? 1 : 0}
      disabled={disabled || loading ? 1 : 0}
      loading={loading ? 1 : 0}
      style={style}
    >
      {loading && (
        <Loader
          primary={primary ? 1 : 0}
          secondary={secondary ? 1 : 0}
          disabled={disabled || loading ? 1 : 0}
        />
      )}
      {children}
    </StyledButton>
  );
};

export { Button };
