import React from 'react';
import { useParams } from 'react-router-dom';
import { COLORS } from '../config';
import { WrapperCard } from './WrapperCard';

const TravauxCard = ({ chantier }) => {
  let { residenceId } = useParams();

  return (
    <WrapperCard
      style={{
        alignItems: 'center',
        marginTop: 16,
        height: 32,
        backgroundColor: COLORS.primary,
        color: COLORS.classic,
        justifyContent: 'space-between',
      }}
      to={(chantier ? '/journal/' : '/travaux/') + residenceId}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span
          className={`icon-dbi-pic-${chantier ? '_blc-travaux' : '_blc-etape'}`}
          style={{ fontSize: 24, paddingLeft: 8 }}
        />
        <div style={{ marginLeft: 16, fontWeight: 800 }}>
          {chantier ? 'Des nouvelles du chantier' : 'Où en sont les travaux ?'}
        </div>
      </div>
      <span
        className='icon-dbi-pic-_selectionner'
        style={{ fontSize: 20, marginRight: 8 }}
      ></span>
    </WrapperCard>
  );
};

export { TravauxCard };
