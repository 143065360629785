import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COLORS, commonStyle } from '../config';

const MDiv = styled(motion.div).attrs({ layout: true })`
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    `}
  background-color: ${COLORS.classic};
  display: flex;
  padding: 8px;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  text-align: left;
  overflow: hidden;
  border-radius: ${commonStyle.borderRadius}px; //fix to avoid overflow on Wrapper
  color: ${COLORS.text};
`;

const WrapperCard = ({ children, to, shadow, style, row = true }) => {
  return to ? (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <MDiv style={style} row={row} shadow={shadow || to}>
        {children}
      </MDiv>
    </Link>
  ) : (
    <MDiv style={style} row={row} shadow={shadow || to}>
      {children}
    </MDiv>
  );
};

export { WrapperCard };
