import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';

const Svg = styled.svg`
  /* set SVG dimensions in ems; i.e. relative to the font size so that it scales with the size of the text in the label */
  width: 1.2em;
  height: 1.2em;
  * {
    transition: all 0.1s linear;
  }
`;

const SInput = styled.input`
  box-shadow: none;
  position: absolute;
  left: 0;
  opacity: 0.01;
  & + svg {
    cursor: pointer;
    color: ${COLORS.secondary};
  }
  &:focus:not(:focus-visible) + svg {
    outline: none;
  }
  &:focus + svg {
    outline: 3px solid ${COLORS.secondary};
    outline-offset: 2px;
  }
  &:checked + svg {
    .checkbox__bg {
      fill: ${COLORS.secondary};
      stroke: ${COLORS.secondary};
    }

    .checkbox__checkmark {
      stroke: ${COLORS.classic};
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  position: relative;
  padding-left: 0.3em;
  line-height: 2;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Checkbox = ({ checked, onChange, name, label, defaultChecked }) => {
  return (
    <Label>
      <SInput
        type='checkbox'
        onChange={onChange}
        id={name}
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        value={name}
      ></SInput>
      <Svg
        width='32'
        height='32'
        viewBox='-4 -4 39 39'
        aria-hidden='true'
        focusable='false'
      >
        <rect
          className='checkbox__bg'
          width='35'
          height='35'
          x='-2'
          y='-2'
          stroke='currentColor'
          fill='none'
          strokeWidth={3}
          rx='6'
          ry='6'
        ></rect>
        <polyline
          className='checkbox__checkmark'
          points='4,14 12,23 28,5'
          stroke='transparent'
          strokeWidth={4}
          fill='none'
        ></polyline>
      </Svg>
      <span style={{ marginLeft: 8 }}>{label}</span>
    </Label>
  );
};

export { Checkbox };
