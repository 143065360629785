import React from 'react';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';

const Popup = styled.div`
  position: fixed;
  border: 1px solid ${COLORS.disabled};
  border-radius: ${commonStyle.borderRadius}px;
  background-color: ${COLORS.classic};
  padding: 8px;
  z-index: 2;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  max-width: 90vw;
`;

const Triangle = styled.div`
  position: absolute;
  height: 0;
  width: 0;
`;
const TriangleBorder = styled.div`
  position: absolute;
  height: 0;
  width: 0;
`;

const textToShow = {
  ios: {
    text: (
      <>
        <img
          src='/images/add-homescreen-ios.png'
          width={30}
          height={'auto'}
          style={{ marginRight: 8 }}
          alt='share'
        />
        <div style={{ textAlign: 'center' }}>
          Pour installer l'application, cliquez sur le bouton de partage
          <img
            src='/images/icon-share-ios.png'
            width={28}
            height={'auto'}
            alt='share'
            style={{ marginBottom: -10 }}
          />
          puis "Ajouter à l'écran d'accueil".
        </div>
      </>
    ),
    position: { bottom: 18, left: '50%', transform: 'translateX(-50%)' },
    triangle: {
      bottom: -20,
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `20px solid ${COLORS.classic}`,
      borderRight: '20px solid transparent',
      borderLeft: '20px solid transparent',
    },
    triangleBorder: {
      bottom: -21,
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `21px solid ${COLORS.disabled}`,
      borderRight: '21px solid transparent',
      borderLeft: '21px solid transparent',
    },
  },
  ipad: {
    text:
      "Pour installer l'application, cliquez sur le bouton de partage, puis \"Ajouter à l'écran d'accueil\".",
    position: { top: -10, right: '20px' },
    triangle: { bottom: '-50%', left: '50%', transform: 'translateX(-50%)' },
  },
  samsung: {
    text:
      'Pour installer l\'application, cliquez sur le bouton de téléchargement, puis "installer".',
    position: { top: 21, right: 8 },
    triangle: {
      top: -22,
      right: 44,
      borderBottom: `20px solid ${COLORS.classic}`,
      borderRight: '20px solid transparent',
      borderLeft: '20px solid transparent',
    },
    triangleBorder: {
      top: -22,
      right: 44,
      borderBottom: `21px solid ${COLORS.disabled}`,
      borderRight: '21px solid transparent',
      borderLeft: '21px solid transparent',
    },
  },
  mobile: {
    text: (
      <div>
        Ce site a une version quer vous pouvez installer, pour cela utilisez
        Safari
      </div>
    ),
    position: { bottom: 12, left: '50%', transform: 'translateX(-50%)' },
    triangle: {
      bottom: -20,
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `20px solid ${COLORS.classic}`,
      borderRight: '20px solid transparent',
      borderLeft: '20px solid transparent',
    },
    triangleBorder: {
      bottom: -21,
      left: '50%',
      transform: 'translateX(-50%)',
      borderTop: `21px solid ${COLORS.disabled}`,
      borderRight: '21px solid transparent',
      borderLeft: '21px solid transparent',
    },
  },
  firefoxAndroid: {
    text: (
      <>
        <img
          src='/images/add-homescreen-ios.png'
          width={30}
          height={'auto'}
          style={{ marginRight: 8 }}
          alt='share'
        />
        <div style={{ textAlign: 'center' }}>
          Pour installer l'application, cliquez sur le bouton de menu puis
          "Installer".
        </div>
      </>
    ),
    position: { bottom: 18, right: 8 },
    triangle: {
      bottom: -20,
      right: -8,
      transform: 'translateX(-50%)',
      borderTop: `20px solid ${COLORS.classic}`,
      borderRight: '20px solid transparent',
      borderLeft: '20px solid transparent',
    },
    triangleBorder: {
      bottom: -21,
      right: -8,
      transform: 'translateX(-50%)',
      borderTop: `21px solid ${COLORS.disabled}`,
      borderRight: '21px solid transparent',
      borderLeft: '21px solid transparent',
    },
  },
};

const InstallPrompt = () => {
  const [installPrompt, setInstallPrompt] = React.useState(null);

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipod/.test(userAgent);
  };
  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    'standalone' in window.navigator && window.navigator.standalone;

  const isSafari = () => {
    return /Version/.test(window.navigator.userAgent);
  };

  React.useEffect(() => {
    // Checks if should display install popup notification:
    if (isIos() && isSafari() && !isInStandaloneMode()) setInstallPrompt('ios');
    else if (
      RegExp(/SamsungBrowser/i).test(window.navigator.userAgent) &&
      !isInStandaloneMode()
    )
      setInstallPrompt('samsung');
    else if (
      (RegExp(/CriOS/).test(window.navigator.userAgent) ||
        RegExp(/FxiOS/).test(window.navigator.userAgent)) &&
      !isInStandaloneMode()
    )
      setInstallPrompt('mobile');
    else if (RegExp(/Android.*Firefox/).test(window.navigator.userAgent))
      setInstallPrompt('firefoxAndroid');
    setTimeout(() => setInstallPrompt(null), 30000);
  }, []);

  return installPrompt !== null ? (
    <Popup style={textToShow[installPrompt]?.position}>
      {textToShow[installPrompt]?.text}
      <TriangleBorder style={textToShow[installPrompt]?.triangleBorder} />
      <Triangle style={textToShow[installPrompt]?.triangle} />
    </Popup>
  ) : null;
};

export { InstallPrompt };
