import React from 'react';
import { COLORS } from '../config';
import { BottomMenu } from './BottomMenu';
import { DesktopMenu } from './DesktopMenu';
import { Menu } from './Menu';

const PageMenuWrapper = ({ lots, lot, disconnect, children }) => {
  return (
    <div
      style={{
        padding: 16,
        paddingBottom: 64 + 16,
        marginBottom: 0,
        backgroundColor: COLORS.background,
        position: 'relative',
      }}
    >
      <Menu style={{ marginBottom: 26 }} />
      {children}
      <BottomMenu />
      <DesktopMenu lots={lots} lot={lot} disconnect={disconnect} />
    </div>
  );
};

export { PageMenuWrapper };
