import React from 'react';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';

const BoxDiv = styled.div`
  background-color: ${COLORS.secondary};
  border-radius: ${commonStyle.borderRadius}px;
  padding: 4px;
  margin: 20px;
  color: #ffffff;
  text-align: center;
`;

const ErrorBox = ({ error, style, ...props }) => {
  return error ? <BoxDiv style={style}>{error}</BoxDiv> : null;
};

export { ErrorBox };
