import React from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { COLORS } from '../config';

const Menu = ({ style }) => {
  const { path } = useRouteMatch();
  const { residenceId } = useParams();
  return (
    <div className={'menudiv'} style={style}>
      <img
        src='/images/logoFull-Dark.png'
        alt='Logo DBI'
        width={'auto'}
        height={40}
      />
      <Link
        className='menuIcon'
        to={
          path.includes('/menu/') ? '/' + residenceId : '/menu/' + residenceId
        }
        style={{
          backgroundColor: COLORS.background,
          color: COLORS.primary,
        }}
      >
        {path.includes('/menu/') ? (
          <span
            className='icon-dbi-pic-simple-2_Close'
            style={{ fontSize: 20 }}
          ></span>
        ) : (
          <span
            className='icon-dbi-pic-simple-2_Menu'
            style={{ fontSize: 20 }}
          ></span>
        )}
      </Link>
    </div>
  );
};

export { Menu };
