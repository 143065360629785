import React from 'react';
import { COLORS } from '../config';

const LoginHeaderWrapper = ({ children, style, ...props }) => {
  return (
    <div
      style={{
        padding: 16,
        paddingBottom: 64,
        backgroundImage: `url('/images/login_background.png')`,
        backgroundColor: COLORS.primary + 'E6',
        backgroundBlendMode: 'darken',
        backgroundSize: 'cover',
        borderRadius: '0 0 300px 300px', //FIXME : border depending on screen or div width ??
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 0,
        ...style,
      }}
    >
      <img
        src='/images/logoFull.png'
        alt='Logo DBI'
        width={200}
        style={{ marginTop: 48 }}
      />
      {children}
    </div>
  );
};

export { LoginHeaderWrapper };
