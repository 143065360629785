import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageHeader } from '../Components';
import { reservationsGet } from '../API';
import { COLORS } from '../config';


const Rdv = ({ lot }) => {


  const reservationId = lot && lot.reservationId ? lot.reservationId : null ;
  const [reservationsData, setReservationsData] = useState(null);

  useEffect(() => {
	const fetchReservations = async () => {
	  try {
		const reservationsResponse = await reservationsGet({ reservationId });
		setReservationsData(reservationsResponse);
	  } catch (error) {
		console.error("Erreur lors de la récupération des données de réservation :", error);
	  }
	};

	fetchReservations();
  }, [reservationId]);

  return (
	<>
	  <PageHeader title={'Prendre rendez-vous'} />
	  {reservationsData && reservationsData[0] ?
	  <Link href={reservationsData[0].urlRdv} style={{
		width: '100%',
		textAlign: 'center',
		overflow: 'auto',
		position: 'relative',
		fontSize: 14,
		color: COLORS.primary
	  }}><p>{reservationsData[0].urlRdv}</p></Link>
	  : <p>Pas de lien disponible</p>}
	</>
  );

}

export { Rdv };
