import React from 'react';
import PropTypes from 'prop-types';

import { Notification } from './Notification';
import { AnimatePresence, AnimateSharedLayout } from 'framer-motion';

const NotificationsManager = ({ setNotify }) => {
  const [notifications, setNotifications] = React.useState([]);

  let counter = React.useRef(0).current;

  let createNotification = ({ type, duration, children }) => {
    counter++;
    setNotifications((prevNotifications) => {
      return [
        ...prevNotifications,
        {
          children,
          type,
          duration,
          id: counter,
        },
      ];
    });
  };

  React.useEffect(() => {
    setNotify(({ type, duration, children }) =>
      createNotification({ type, duration, children })
    );
  }, [setNotify]);

  let deleteNotification = (id) => {
    const filteredNotifications = notifications.filter(
      (notif) => id !== notif.id
    );
    setNotifications(filteredNotifications);
  };

  return (
    <AnimateSharedLayout>
      <AnimatePresence>
        {notifications.map(({ id, ...props }) => (
          <Notification
            key={id}
            id={id}
            onDelete={() => deleteNotification(id)}
            {...props}
          />
        ))}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
};

NotificationsManager.propTypes = {
  setNotify: PropTypes.func.isRequired,
};

export { NotificationsManager };
