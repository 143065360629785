import React, { useEffect, useState } from 'react';
import { fileGetByPath } from '../API';
import {
  ContactCard,
  H4,
  MeetingCard,
  PageHeader,
} from '../Components';
import { commonStyle } from '../config';

const Copro = ({ lot }) => {
  const [logo, setlogo] = useState(null);
  useEffect(() => {
    if (lot?.program?.syndicate?.logo) initData();
  }, [lot]);

  const initData = async () => {
    try {
      const getlogo = await fileGetByPath({
        filename: lot?.program?.syndicate?.logo,
      });
      setlogo(getlogo.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader title='Syndic de copropriété' />
      <img
        src={logo || '/test/syndic.png'}
        alt='logo_syndic'
        width={120}
        height='auto'
        style={{ borderRadius: commonStyle.borderRadius, margin: 8 }}
      />
      <H4 style={{ marginLeft: 8 }}>{lot?.program?.syndicate?.name}</H4>
      <p style={{ marginLeft: 8 }}>{lot?.program?.syndicate?.description}</p>
      <H4 style={{ marginLeft: 8, marginTop: 16, marginBottom: 8 }}>
        Prochaine Réunion :
      </H4>
      <MeetingCard
        date={lot?.program?.syndicate?.meetingDate}
        description={lot?.program?.syndicate?.meeting}
      ></MeetingCard>
      <H4 style={{ margin: 8 }}>Votre contact :</H4>
      <ContactCard
        hideTitle
        contact={{
          phone: lot?.program?.syndicate?.phone || 'N/C',
          email: lot?.program?.syndicate?.email || 'N/C',
          name: lot?.program?.syndicate?.contactName || 'N/C',
        }}
      />
    </>
  );
};

export { Copro };
