import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../config';

const Box = styled.div`
  margin-left: 16px;
  margin-bottom: -3px;
  padding: 8px;
  display: flex;
  border-left: ${(props) =>
    `3px ${props.active ? 'solid' : 'dotted'} ${COLORS.primary}`};
  ${(props) =>
    !props.active &&
    css`
      filter: opacity(0.3);
    `}
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6px;
`;

const IconWork = ({ name }) => (
  <span
    className={`icon-dbi-pic-${name}`}
    style={{
      fontSize: 36,
      color: COLORS.primary,
      marginLeft: 8,
      marginRight: 8,
    }}
  />
);

const WorkflowItem = ({ title, startDate, endDate, icon, active }) => {
  return (
    <Box active={active}>
      <IconWork name={icon} />
      <CenterDiv>
        <p style={{ margin: 0, lineHeight: '12px' }}>{title}</p>
          {startDate && endDate ? (
          <span style={{ fontSize: 12, fontWeight: 'lighter' }}>
            {new Date(startDate).toLocaleDateString()}
            {new Date(endDate).toLocaleDateString() !== new Date(startDate).toLocaleDateString() && (
              <> - {new Date(endDate).toLocaleDateString()}</>
            )}
          </span>

        ) : ""}
      </CenterDiv>
    </Box>
  );
};

export { WorkflowItem };
