import React from 'react';
import styled from 'styled-components';
import { PageHeader, MeetingCard } from '../Components';
import { COLORS } from '../config';

const Liner = styled.div`
  height: 30px;
  margin-left: 16px;
  border-left: ${(props) =>
    `2px ${props.active ? 'solid' : 'dotted'} ${COLORS.primary}`};
`;

const Meeting = ({ lot }) => {
  return (
    <>
      <PageHeader title={'Rendez-vous'} />
      <MeetingCard style={{ marginTop: 32 }} date={lot?.tmaDate}>
        Deadline TMA
      </MeetingCard>
      <Liner />
      <MeetingCard date={lot?.prestaDate}>
        Deadline Choix des prestations
      </MeetingCard>
      <Liner />
      <MeetingCard date={lot?.cloisonsDate}>
        Date de visite cloisons
      </MeetingCard>
      <Liner />
      <MeetingCard date={lot?.predeliveryDate}>
        Date de pré-livraison
      </MeetingCard>
      <Liner />
      <MeetingCard date={lot?.delivery}>Date de livraison</MeetingCard>
      <Liner />
      <MeetingCard
        date={lot?.agDate}
        desciption='42 rue de la station spatiale 75042 MARS'
      >
        Date de la 1ère réunion d'AG
      </MeetingCard>
    </>
  );
};

export { Meeting };
