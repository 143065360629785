export const COLORS = {
  primary: '#17213c',
  secondary: '#E04C38',
  error: '#E04C38',
  classic: '#FFFFFF',
  disabled: '#E2E2E2',
  background: '#F2F2F2',
  text: '#17213C',
  grey: '#707070',
  success: '#2fbd2f',
};

export const commonStyle = {
  borderRadius: 10,
};

export const menus = [
  { title: 'Votre bien', icon: '_Dashboard', link: '/programme/' },
  { title: 'Avancée des travaux', icon: '_menu-travaux', link: '/travaux/' },
  {
    title: 'Journal du chantier',
    icon: '_menu-journal-chantier',
    link: '/journal/',
  },
  { title: 'Documents', icon: '_menu-document', link: '/documents/' },
  {
    title: 'Choix des prestations',
    icon: '_menu-prestations',
    link: '/prestations/',
  },
  { title: 'Travaux modificatifs', icon: '_menu-prestations', link: '/tma/' },
  { title: 'Appels de fonds', icon: '_menu-appel-fond', link: '/appelsfonds/' },
  { title: 'Livraison', icon: '_menu-livraison', link: '/livraison/' },
  /*{ title: 'Service après-vente', icon: '_menu-sav', link: '/sav/' }, */
  { title: 'Copropriété', icon: '_menu-copro', link: '/copro/' },
  { title: 'Compte', icon: '_menu-compte', link: '/compte/' },
  { title: 'Contacts', icon: '_menu-contact', link: '/contacts/' },
  { title: 'Prendre rendez-vous', icon: '_Rdv', link: '/rdv/' },
];
