import React, { useState } from 'react';
import { userLogin } from '../API';
import {
    Button,
    ErrorBox,
    H1,
    H2,
    Input,
    LoginHeaderWrapper,
} from '../Components';
import { COLORS } from '../config';

export const Login = (props) => {
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userid = urlParams.get('userid');
    const token = urlParams.get('token');
    const badLogin = 'Identifiant et/ou mot de passe incorrect(s)';
    const badConn = 'Erreur de connexion réseau';

    const handleLogin = async (e) => {
        e.preventDefault();
        if (!email || !password) return setError('Champs vide...');
        setError(null);
        setLoadingLogin(true);
        try {
            const resp = await userLogin({ email, password, userid, token });
            if (!resp.authent === 'success') throw new Error(resp);
            props.handleLogin({ user: resp.user, accessToken: resp.accessToken });
        } catch (error) {
            setError(
                error?.status === 404 ?
                badLogin :
                error?.status === 401 ?
                badLogin :
                badConn
            );
            setLoadingLogin(false);
        }
    };

    const automaticLogin = async (e) => {
        try {
            const resp = await userLogin({ email, password, userid, token });
            if (!resp.authent === 'success') throw new Error(resp);
            props.handleLogin({ user: resp.user, accessToken: resp.accessToken });
        } catch (error) {
            setError(
                error?.status === 404 ?
                badLogin :
                error?.status === 401 ?
                badLogin :
                badConn
            );
            setLoadingLogin(false);
        }
    };

    if (token) {
        automaticLogin();
    }

    return (
<div style = {{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100%', textAlign: 'center' }}>
    <LoginHeaderWrapper>
        <H1 style={{ marginTop: 0 }}>ESPACE CLIENT</H1>
        <H2 style={{ marginTop: 64 }}>Identification</H2>
        <form>
            <Input value={email} type='email' onChange={(e)=> setEmail(e.target.value)} style={{ minWidth: 200 }} placeholder='Votre identifiant'></Input>
            <Input value={password} type='password' onChange={(e)=> setPassword(e.target.value)} style={{ minWidth: 200 }}  placeholder='Votre mot de passe'></Input>
            <ErrorBox error={error} />
            <div style={{ margin: 8 }}>
                <Button type='submit' loading={loadingLogin} disabled={!email || !password} onClick={handleLogin} style={{ minWidth: 150 }}>Connexion</Button>
            </div>
        </form>
    {/*<a
          href='/'
          style={{
            fontSize: 12,
            fontWeight: 300,
            fontStyle: 'italic',
            textDecoration: 'underline',
            color: COLORS.classic,
          }}
        >
          Identifiant ou mot de passe oublié ?
        </a>*/}<br /><br />
    </LoginHeaderWrapper>
    <div style={{ marginTop: 32 }}>
    </div>
</div>
  );
};
