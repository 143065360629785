import React from 'react';
import { PageHeader } from '../Components';
import styled from 'styled-components';
import { COLORS } from '../config';

const MentionsLegales = ({ lot }) => {

  return (
    <div>
    <h1>Politique de protection des données personnelles</h1>
    DEMATHIEU BARD IMMOBILIER accorde la plus grande importance à la protection des données à caractère personnel. La présente politique de protection des données à caractère personnel a pour objectif de définir les principes et lignes directrices mis en œuvre par DEMATHIEU BARD IMMOBILIER en matière de traitement des données à caractère personnel des utilisateurs du site internet, des portails immobiliers, des plateformes des réseaux sociaux ou des mises en relations avec les services, les équipes de vente DEMATHIEU BARD IMMOBILIER et ses partenaires.
    <br/><br/>
    La notion de « donnée à caractère personnel » ou « données personnelles » correspond à la définition donnée par l’article 4 du Règlement Général de l’Union Européenne sur la protection des Données (RGPD 2016/679) c’est-à-dire à toute information susceptible de permettre l’identification d’une personne physique de manière directe ou indirecte : nom, prénom, adresse IP, adresse email, etc.
    <br/><br/>
    DEMATHIEU BARD IMMOBILIER affirme son attachement au respect des lois et règlements applicables en matière de protection des données, et s’engage à préserver leur sécurité, leur confidentialité et leur intégrité.
    <br/><br/>
   <h2>1. CHAMP D’APPLICATION ET EXCLUSION</h2>
    La présente politique s’applique à l’ensemble des traitements de données à caractère personnel effectués par DEMATHIEU BARD IMMOBILIER à partir :<br/><br/>
      <ul>
        <li>De ses sites programmes internet,</li>
        <li>Des formulaires de contacts provenant des portails immobiliers de sites d’éditeurs,</li>
        <li>Des événements (portes ouvertes, salons, inaugurations…),</li>
        <li>De ses plateformes sur les réseaux sociaux,</li>
        <li>De son centre d’appels téléphonique.</li>
      </ul>

    <h2>2. DONNÉES COLLECTÉES</h2>
    DEMATHIEU BARD IMMOBILIER s’engage à ne collecter que des données strictement nécessaires à la réalisation de son activité. Les données personnelles sont collectées directement auprès de vous et ne sont utilisées que pour les usages qui ont été portés à votre connaissance.<br/><br/>
    <b>Les données collectées directement depuis les formulaires sites programmes DE DEMATHIEU BARD IMMOBILIER et formulaires de contacts provenant des portails immobiliers</b><br/><br/>
    Il s’agit des données que vous nous fournissez directement par le biais de formulaires (demandes d’informations sur un programme immobilier) mis en ligne sur le site de DEMATHIEU BARD IMMOBILIER et sur les portails immobiliers :
    <ul>
      <li>Civilité,</li>
      <li>Prénom et nom de famille,</li>
      <li>Adresse postale,</li>
      <li>Adresse de courrier électronique,</li>
      <li>Numéro de téléphone,</li>
      <li>Vos attentes d’informations sur un programme</li>
    </ul>
    Ces données sont transmises directement à DEMATHIEU BARD IMMOBILIER par flux informatique. À tout moment, vous disposez de la faculté de modifier ces informations en nous adressant un message à relation_clients@demathieu-bard.fr<br/><br/>
    <h3>Les données collectées directement lors de prise de contact</h3>
    Il s’agit des données que vous nous fournissez directement au cours d’événements commerciaux (portes ouvertes, salons, inaugurations…), directement à son centre d’appels téléphonique et dans l’espace client individuel :
    <ul>
      <li>Civilité,</li>
      <li>Prénom et nom de famille,</li>
      <li>Adresse postale,</li>
      <li>Adresse de courrier électronique,</li>
      <li>Numéro de téléphone,</li>
      <li>Informations relatives à votre patrimoine et vos revenus</li>
      <li>Informations relatives à votre situation familiale</li>
    </ul>
    À tout moment, vous disposez de la faculté de modifier ces informations en nous adressant un message à relation_clients@demathieu-bard.fr et en vous référant à l’article 8 de la présente Politique.<br/><br/>
    <h3>Les données de connexion collectées indirectement</h3>
    Il s’agit des données que DEMATHIEU BARD IMMOBILIER collecte à partir de votre navigation sur les sites internet et les réseaux sociaux telles que :
    <ul>
      <li>la date, l’heure de la connexion et/ou navigation,</li>
      <li>le type de navigateur,</li>
      <li>la langue du navigateur,</li>
      <li>URL consultée,</li>
      <li>l’adresse de protocole Internet et l’adresse IP.</li>
    </ul>
    Le traitement de ces données de connexion se fait dans le respect de la règlementation applicable en matière de protection des données à caractère personnel. L’analyse de ces données a pour but :<br/>
    <ul>
      <li>de produire des indicateurs non nominatifs et de vous proposer un service plus personnalisé sur le site internet de DEMATHIEU BARD IMMOBILIER ;</li>
      <li>de s’assurer que l’utilisation du site est conforme aux Conditions Générales d’Utilisation.</li>
    </ul>
    <h3>Cookies et mesure d’audience</h3>
    Les cookies sont des fichiers stockés sur votre ordinateur par les sites web que vous visitez et contiennent des informations telles que vos préférences de navigation ou votre statut de connexion.
    Lors de la collecte par DEMATHIEU BARD IMMOBILIER des informations générées par ces cookies, l’adresse IP est rendue anonyme. DEMATHIEU BARD IMMOBILIER pourrait utiliser utilise les cookies suivants :
    <ul>
      <li>techniques (pour faciliter et améliorer vos connexions)</li>
      <li>de mesure d’audience (pour nous permettre de connaître l’audience des différentes rubriques et pages du site)</li>
      <li>informatifs (pour vous adresser de l’information ciblée en fonction des attentes déduites de votre activité sur le site)</li>
      <li>réseaux sociaux (pour connaître vos habitudes et centres d’intérêt à partir de votre navigation sur internet)</li>
    </ul>
    <h2>3. UTILISATION DES DONNÉES COLLECTÉES</h2>
    DEMATHIEU BARD IMMOBILIER traite les données à caractère personnel que vous lui avez confiées pour vous permettre de recevoir une documentation, un complément d’informations sur un programme, les informations relatives aux nouveautés commerciales (newsletter, emailing, courrier). Les informations éventuellement recueillies par DEMATHIEU BARD IMMOBILIER ne sont jamais communiquées à des tiers.
    <h2>4. DESTINATAIRES DES DONNÉES À CARACTÈRE PERSONNEL</h2>
    Conscient de l’importance de vos données celles-ci ne font l’objet d’aucune transaction commerciale auprès de quelque organisme que ce soit.<br/><br/>
    Toutes les données à caractère personnel étant confidentielles, leur accès est limité aux services de DEMATHIEU BARD IMMOBILIER et à ses partenaires avec lesquels DEMATHIEU BARD IMMOBILIER est liée contractuellement pour pouvoir exécuter les services rendus aux utilisateurs dans le cadre de ses missions.<br/><br/>
    Ainsi, si elle estime qu’un tel transfert est nécessaire pour des raisons techniques, DEMATHIEU BARD IMMOBILIER peut être amenée à transférer tout ou partie des données à caractère personnel à des tiers de confiance qui les traitent pour son compte, selon ses instructions, conformément à la présente Politique de protection des données et dans le respect de toute mesure appropriée de sécurité et de confidentialité. Les situations dans lesquels ces transferts peuvent avoir lieu : routage de courriers, d’emailing, de prospection, etc.<br/><br/>
    Toutes les personnes ayant accès à vos données à caractère personnel sont tenues par une obligation de confidentialité et s’exposent à des sanctions si elles ne respectent pas ces obligations.
    <h2>5. DURÉE DE CONSERVATION DE VOS DONNÉES</h2>
    A partir du 25 mai 2018, toutes les données collectées lors de la création de votre compte et/ou traitées postérieurement à cette date seront conservées pendant une durée de dix ans après votre dernier contact avec DEMATHIEU BARD IMMOBILIER ou votre dernière connexion sur le site DEMATHIEU BARD IMMOBILIER.
    <h2>6. SÉCURITÉ DE VOS DONNÉES</h2>
    DEMATHIEU BARD IMMOBILIER s’attache à conserver les données à caractère personnel qu’elle traite de manière sécurisée et uniquement pendant la durée nécessaire à la réalisation de la finalité poursuivie par le traitement.<br/><br/>
    Dans cette perspective, DEMATHIEU BARD IMMOBILIER prend les mesures physiques, techniques et organisationnelles appropriées pour prévenir et empêcher, dans toute la mesure du possible, toute altération, perte de vos données ou tout accès non autorisé à celles-ci.<br/><br/>
    Si DEMATHIEU BARD IMMOBILIER apprenait qu’un tiers auquel elle a communiqué des données à caractère personnel aux fins énoncées ci-dessus, utilise ou divulgue des données à caractère personnel sans respecter la présente politique ou en violation de la législation applicable, elle prendrait toute mesure pour mettre fin immédiatement à une telle utilisation.<br/><br/>
    Il est par ailleurs rappelé que chaque utilisateur contribue à la protection de ses données à caractère personnel en se conformant aux bonnes pratiques en matière d’utilisation des outils informatiques (par exemple ne pas retranscrire son mot de passe) et aux conditions générales d’utilisation des site DEMATHIEU BARD IMMOBILIER.
    <h2>7. STOCKAGE DES DONNÉES</h2>
    Les données à caractère personnel sont stockées soit dans les bases de données de DEMATHIEU BARD IMMOBILIER, soit dans celles de prestataires de services avec lesquels DEMATHIEU BARD IMMOBILIER crée une relation contractuelle dédiée. La base de données principale exploitée par DEMATHIEU BARD IMMOBILIER est fournie par la société Markus qui a présenté à DEMATHIEU BARD IMMOBILIER toutes les garanties de sécurisation des données.
    <h2>8. DROITS RELATIFS AUX DONNÉES À CARACTÈRE PERSONNEL</h2>
    Conformément aux articles 15 et suivants du Règlement Général relatif à la Protection des Données 2016/679 (“RGPD”), vous disposez d’un droit d’accès, de rectification, de suppression, de portabilité, et d’opposition aux traitements de données vous concernant, soit par mail à l’adresse relation_clients@demathieu-bard.fr, soit par courrier à l’adresse suivante :<br/><br/>
    DEMATHIEU BARD IMMOBILIER<br/>
    Société par actions simplifiée au capital de 30.000.000 euros<br/>
    SIREN 353 708 746 METZ<br/>
    Siège social : 17 rue Vénizélos – 57950 Montigny-lès-Metz<br/>
    Téléphone : 01 41 76 09 10<br/><br/>
    DEMATHIEU BARD IMMOBILIER s’engage à traiter votre demande dans les plus brefs délais.

    <h2>9. RÉCLAMATIONS</h2>
    Si vous avez des raisons de penser que la sécurité de vos données à caractère personnel a été compromise ou que ces données ont fait l’objet d’une utilisation abusive, vous êtes invités à contacter le directeur de publication, Philippe JUNG, Directeur Général DEMATHIEU BARD IMMOBILIER.<br/><br/>
    DEMATHIEU BARD IMMOBILIER instruira ainsi les réclamations concernant l’utilisation et la divulgation de données à caractère personnel et tentera de trouver une solution conformément à la réglementation en vigueur.
    <h2>10. MISE À JOUR DE LA POLITIQUE</h2>
    La présente Politique de protection des données à caractère personnel peut être mise à jour, à tout moment, en fonction des besoins de DEMATHIEU BARD IMMOBILIER ou si la loi l’exige.


    </div>
  );
};

export { MentionsLegales };
