import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import { Button } from './Button';
import { H4, TR } from './H1';
import { WrapperCard } from './WrapperCard';
import { fileGet } from "../API";

const drawerVariants = {
    initial: { height: '0' },
    in: {
        height: '100%',
    },
    exit: { height: '0' },
};

const Open = styled.div`
  font-size: 20px;
  color: rgb(255, 255, 255);
  position: absolute;
  right: 8px;
  top: 7px;
  width: 20px;
  height: 20px;
  border: 1px solid ${COLORS.classic};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
`;

const AppelDrawer = ({
        title,
        active,
        passed,
        toPay,
        ADF,
        cumul,
        echeance,
        call,
        date,
        datePaid,
        paid,
        link,
        handleActive,
    }) => {

        const handleDownload = async () => {
            try {
                await fileGet({ filename: link, download: true });
            } catch (error) {
                console.error(error);
            }
        };

        return (
    <WrapperCard as={motion.div} shadow style={{
        marginTop: 16,
        padding: 0,
      }} row={false}>
    <motion.div layout
        style = {
            {
                position: 'relative',
                cursor: 'pointer',
                padding: 8,
                backgroundColor: toPay ?
                    COLORS.secondary :
                    passed ?
                    COLORS.primary :
                    COLORS.grey,
            }
        } onClick = { handleActive } >
        <H4 style = { { color: COLORS.classic, textAlign: 'center' } } > { title } < /H4>
        <Open> { active ? '-' : '+' } </Open>
    </motion.div>
    <AnimatePresence>
        {active && (
        <motion.div layout transition={{ ease: 'easeOut', duration: 0.5 }} initial='initial' animate='in' exit='out' variants={drawerVariants} style={{
              width: '100%',
              height: '100%',
              backgroundColor: COLORS.classic,
            }}>
            <table style={{
                    width: 'calc(100% - 16px)',
                    margin: 8,
                    borderCollapse: 'collapse',
                }}>
                <tbody>
                    <tr style={{ textAlign: 'center', lineHeight: '24px' }}>
                        <td colSpan={2}>
                            Soit <span style={{ fontWeight: 700 }}>{ADF}%</span> du total des appels de fonds.
                        </td>
                    </tr>
                    <tr style={{
                                    textAlign: 'center',
                                    backgroundColor: COLORS.background,
                                    lineHeight: '24px',
                                  }}>
                        {/*<td colSpan={2}>*/}
                            {/* <span style={{ fontWeight: 700 }}>{cumul}%</span> de ADF*/}
                            {/* cumulé*/}
                            {/*</td>*/}
                    </tr>
                    <TR>
                        <td>Date de facturation : </td>
                        <td> {date || ''}</td>
                    </TR>
                    <TR>
                        <td>Montant échéance TTC : </td>
                        <td>{Number(echeance || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                    </TR>
                    <TR>
                        <td>Montant à verser TTC : </td>
                        <td>{Number(call || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                    </TR>
                    {/*<TR>*/}
                        {/* <td>Date de réglement : </td>*/}
                        {/* <td>{datePaid || ''}</td>*/}
                        {/*</TR> */}
                    <TR>
                        <td>Montant déjà versé TTC : </td>
                        <td>{Number(paid || 0).toLocaleString('fr-FR', {style:'currency', currency:'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>
                    </TR>
                </tbody>
            </table>
            {(passed || toPay) && (
            <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {(link) && (
                <Button primary={passed && !toPay} secondary={toPay} style={{ margin: 16, padding: '12px 32px' }} onClick={handleDownload}> Télécharger la facture </Button>
                )}
            </div>
            )}
        </motion.div>
        )}
        </AnimatePresence>
    </WrapperCard>
  );
};

export { AppelDrawer };
