import React from 'react';
import { ContactCard, DocumentCard, H4, PageHeader } from '../Components';

const SAV = ({ lot }) => {
  const [reservesDocs, setReservesDocs] = React.useState([]);
  React.useEffect(() => {
    if (!lot || !lot.documents) return;
    setReservesDocs(lot.documents.filter((doc) => doc.objectsTypeId === 4));
  }, [lot]);
  return (
    <>
      <PageHeader
        title={'Service après-vente'}
        subtitle={
          //FIXME subtitle
          'Nec et nisl nec leo laoreet laoreet at eu mi. Donec scelerisque eleifend massa, quis hendrerit orci malesuada eget. Sed laoreet metus nec nibh luctus.'
        }
      />
      {reservesDocs.length > 0 && (
        <H4 style={{ marginLeft: 8, marginBottom: 8 }}>Vos documents :</H4>
      )}
      {reservesDocs.map((doc) => (
        <DocumentCard
          title={doc.name || 'Liste de vos réserves'}
          subtitle={
            'Consulter la liste de réserves que vous avez établis lors de votre visite.'
          }
          lotId={lot?.id}
          programId={lot?.program?.id}
          filename={doc.filename}
        />
      ))}
      <H4 style={{ marginLeft: 8, marginBottom: 8, marginTop: 16 }}>
          Suivi des réserves :
      </H4>
      {/* FIXME : empty div for iframe !? */}
      <div
        style={{
          height: "calc(100vh - 64px)",
          backgroundColor: 'white',
          borderRadius: 8,
          textAlign: 'center',
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          fontSize: 32,
          color: 'grey',
        }}
      >
        IFRAME
      </div>
      <H4 style={{ marginLeft: 8, marginBottom: 8, marginTop: 16 }}>
          Adresse de contact :
      </H4>
      {lot?.contact ? (
        <ContactCard
          contact={{
            name: lot.contact.firstname + ' ' + lot.contact.lastname,
            email: lot.contact.email,
            phone: lot.contact.phone,
          }}
        />
      ) : (
        <p style={{ fontStyle: 'italic', fontWeight: 300, marginLeft: 16 }}>
          Il n'y a pas d'interlocuteur désigné sur votre lot pour le moment.
        </p>
      )}
    </>
  );
};

export { SAV };
