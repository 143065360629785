import React from 'react';
import styled from 'styled-components';
import { COLORS, commonStyle } from '../config';

const SSelect = styled.select`
  background: none;
  border: 1px solid ${COLORS.grey};
  border-radius: ${commonStyle.borderRadius * 2}px;
  appearance: none;
  padding: 4px;
  font-size: 14px;
  width: 100%;
  outline: none;
`;

const Option = styled.option`
  font-size: 14px;
`;

const Span = styled.span`
  position: absolute;
  right: 14px;
  top: 40%;
  font-size: 16px;
  pointer-events: none;
  transform: rotate(90deg) translateX(-50%);
  &:active {
    transform: rotate(-90deg);
    top: 20%;
  }
  transition: all 0.2s ease-out;
`;

const Select = ({ name, options, onChange, selected }) => {
  return (
    <div style={{ position: 'relative' }}>
      <SSelect
        onChange={onChange ? onChange : null}
        name={name}
        defaultValue={selected}
      >
        {options.map((opt) => (
          <Option key={'select_' + opt.value} value={opt.value}>
            {opt.name || opt.value}
          </Option>
        ))}
      </SSelect>
      <Span className='icon-dbi-pic-_selectionner'></Span>
    </div>
  );
};

export { Select };
