import React from 'react';
import { DocumentCard, PageHeader, UploadZone, ArkaCard } from '../Components';

const TMA = ({ lot, refresh }) => {
  const [uploadDocuments, setUploadDocuments] = React.useState([]);
  const [localDocuments, setLocalDocuments] = React.useState([]);

    /*const url = "https://demathieu-bard.dev.configurateur-arka.com/?token=" + localStorage.getItem("token");*/
    const url = "https://demathieu-bard.dev.configurateur-arka.com/reservations/" + lot?.reservationId +"/configurateur-tma?token=" + localStorage.getItem("token");

  React.useEffect(() => {
    if (!lot || !lot.documents) return;
    const uploadDocs = lot.documents.filter((doc) => doc.objectsTypeId === 8);
    const restDocs = lot.documents.filter((doc) => doc.objectsTypeId === 7);
    setLocalDocuments(restDocs);
    setUploadDocuments(uploadDocs);
  }, [lot]);
  return (
    <>
      <PageHeader
        title={'Travaux modificatifs'}
        subtitle={
          "Les TMA (travaux modificatifs acquéreurs) sont des modifications d'ordre technique que vous pouvez apporter à votre logement (sous réserve de faisabilité)."
        }
      />
      {/** FIXME : Static number of catalogue de prestations ? Or dynamic ? */}
      {localDocuments.map((doc, i) => (
        <DocumentCard
          key={'prestations_document_' + i}
          style={{ marginTop: 16 }}
          title={doc.name}
          filename={doc.filename}
          programId={lot?.program?.id}
          lotId={null}
          subtitle={doc.description}
        />
      ))}
      <h4 style={{ marginLeft: 8 }}>
        Une fois vos documents remplis, envoyez les :
      </h4>
      <UploadZone
        style={{ margin: 8 }}
        lot={lot}
        typeId={8}
        refresh={refresh}
      />
      {uploadDocuments.length > 0 && (
        <h4 style={{ marginLeft: 8 }}>Vos documents déjà envoyés :</h4>
      )}
      {uploadDocuments.map((doc) => (
        <DocumentCard
          key={doc.name}
          style={{ marginTop: 16 }}
          title={doc.name}
          filename={doc.filename}
          programId={lot?.program?.id}
          lotId={lot?.id}
          subtitle={new Date(doc.send_at).toLocaleDateString()}
        />
      ))}
        {lot?.program?.Arka === true && (
        <h4 style={{ marginLeft: 8 }}>Configurez votre appartement :</h4>
        )}
        {lot?.program?.Arka === true && (
        <ArkaCard
            key={"Configurateur"}
            style={{ marginTop: 16, backgroundColor:'rgb(224, 76, 56)' }}
            title={"Appartement 3D"}
            url={url}
            lotId={lot?.id}
            programId={lot?.program?.id}
        />
        )}
    </>
  );
};

export { TMA };
