import React from 'react';
import { useParams } from 'react-router-dom';

import { WrapperCard } from '../Components';
import { COLORS, commonStyle } from '../config';

const MenuCard = ({ menu, disabled }) => {
  const { residenceId } = useParams();

  return (
    <WrapperCard
      to={disabled ? undefined : menu.link ? menu.link + residenceId : '/'}
      row={false}
      style={{
        alignItems: 'center',
        margin: '8px 4px',
        paddingTop: 12,
        width: 85,
        height: 85,
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          border: `2px solid ${COLORS.disabled}`,
          backgroundColor: disabled ? COLORS.disabled : 'transparent',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: commonStyle.borderRadius,
        }}
      >
        <span
          className={`icon-dbi-pic-${menu.icon}`}
          style={{ fontSize: 36 }}
        />
      </div>
      <div
        style={{
          fontSize: 12,
          fontWeight: 700,
          marginTop: 4,
          textAlign: 'center',
        }}
      >
        {menu.title}
      </div>
    </WrapperCard>
  );
};

export { MenuCard };
