import { AnimateSharedLayout, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CardBienImmo,
  Notifications,
  PageHeader,
  TravauxCard,
//  MenuCard,
} from '../Components';

const Acceuil = ({ user, lot, refresh }) => {
  let { residenceId } = useParams();
  const [lotId, setLotId] = React.useState(null);

  useEffect(() => {
    if (lot && lot?.id !== lotId) {
      setLotId(lot.id);
    }
  }, [lot]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <PageHeader
          title={'Bonjour ' + user.firstname + " " + user.lastname + ( user?.coAcquirer ? " et " + user.coAcquirer : "")}
        subtitle={`Dernière connexion le ${new Date(
          user.updatedAt
        ).toLocaleDateString()}`}
      />
      <CardBienImmo lot={lot} to={'/programme/' + residenceId} />
      <AnimateSharedLayout>
        <motion.div layout>
          <Notifications lot={lot} />
          <TravauxCard />
        </motion.div>
      </AnimateSharedLayout>
     <div className='menuDesktopAcceuil'>
        {/**** <MenuCard
          menu={{
            title: 'Rendez-vous',
            icon: '_Rdv',
            link: '/meeting/',
          }}
          disabled={1}
        />
        <MenuCard
          menu={{
            title: 'Messagerie',
            icon: '_Message',
            link: '/messaging/',
          }}
          disabled={1}
        /> ****/}
      </div>
    </>
  );
};

export { Acceuil };
