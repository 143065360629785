import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import { LI, UL } from './H1';
import { WrapperCard } from './WrapperCard';

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const MP = styled.div`
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  line-height: 18px;
  font-size: 16px;
`;

const CardBienImmo = ({ lot, to }) => {
  return (
    <WrapperCard to={to} style={{ display: 'block', overflow: 'hidden' }}>
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            marginLeft: 8,
            justifyContent: 'space-between',
          }}
        >
          <div>
            <MP bold>
              {lot?.program?.address + "(" + (lot?.program?.city +", "+lot?.program?.postalcode||"XXNNN") + ")"}
            </MP>
            <MP bold>
              {lot?.program?.name}{' '}
              <span style={{ fontWeight: 400, color: COLORS.secondary }}>
                Lot {lot?.reference}
              </span>
            </MP>
          </div>
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              padding: 8,
            }}
          >
            <span
              className='icon-dbi-pic-_selectionner'
              style={{ fontSize: 20 }}
            ></span>
          </div>
        </div>
      </div>
      <FlexDiv>
        <div style={{ flex: 1, borderTop: `1px solid ${COLORS.disabled}` }}>
          <UL>
            {[
              {
                title: (
                  <>
                    {'Surface : ' + lot?.surface_habitable + 'm'}
                    <sup>2</sup>
                  </>
                ),
              },
              {
                title: (lot?.surface_terrasse ? (
                  <>
                    Terrasse : {lot?.surface_terrasse + 'm'}
                    <sup>2</sup>
                  </>
                ):null),
              },
              { title: (lot?.etage === 0 ? 'Étage : RDC' : lot?.etage === 1 ? 'Étage : 1er' : lot?.etage > 1 ? `Étage : ${lot?.etage}ème` : '') }
,
              { title: 'Livraison : ' +  new Date(lot?.program?.deliveryDate).toLocaleDateString() },
            ].map((elem, i) => (
              elem.title &&
              <LI style={{ lineHeight: 1.6 }} key={'li_bien_immo_' + i}>
                {elem.title}
              </LI>
            ))}
          </UL>
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          <img
            style={{
              margin: 0,
              objectFit: 'cover',
              marginRight: -18,
              marginBottom: -17,
            }}
            src={lot?.program?.visuel}
            alt='imageLot'
            width={200}
            height={110}
          />
        </div>
      </FlexDiv>
    </WrapperCard>
  );
};

export { CardBienImmo };
