import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  PageHeader,
  TravauxCard,
  TravauxWorkflowCard,
  WorkflowItem,
} from '../Components';

const Travaux = ({ lot }) => {
  const [workflow, setWorkflow] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setendDate] = useState(0);
  const [maxWorkflow, setMaxWorkflow] = useState(0);
  let { residenceId } = useParams();
  useEffect(() => {
    if (lot?.program?.workflow) {
      let startDate = {};
      let endDate = {};
      let max = lot.program.workflow.reduce((max, val) => {
        if (val.workflowTypeId > max) return val.workflowTypeId;
        else return max;
      }, 0);
      setMaxWorkflow(max);
      for (let i = 1; i <= max; i++) {
          startDate[i] = lot?.program?.workflow.find((wrk) => wrk.workflowTypeId === i)
                    ?.startDate || "";
          endDate[i] = lot?.program?.workflow.find((wrk) => wrk.workflowTypeId === i)
              ?.endDate || "";
      }
        setStartDate(startDate);
        setendDate(endDate);
    }
  }, [lot]);
  // https://app.asana.com/0/1171763383025954/1205477320596838/f Ne pas afficher période de TMA / Choix des prestations quand date dépassée
  const currentDate = new Date();
  const endDate1 = new Date(endDate['1']);
  const endDate6 = new Date(endDate['6']);
  const shouldShowTravauxWorkflowCard1 = endDate1 >= currentDate;
  const shouldShowTravauxWorkflowCard6 = endDate6 >= currentDate;
  return (
    <>
      <PageHeader title={'Avancée des travaux'} />
      <WorkflowItem
        title={'Réservation'}
        icon={'_tr-reservation'}
        active={startDate['2']}
      />
      <WorkflowItem
        title={'Réunion réservataires'}
        icon={'_tr-reunion'}
        active={startDate['19']}
      />
      <WorkflowItem
        title={'Actabilité du programme'}
        icon={'_tr-reservation'}
        active={startDate['14']}
      />
      <WorkflowItem
        title={'Démarrage des travaux'}
        icon={'_tr-demarrage-travaux'}
        startDate={startDate['3']}
        endDate={endDate['3']}
        active={startDate['3']}
      />
	  {shouldShowTravauxWorkflowCard1 && (
      <TravauxWorkflowCard
        style={{ marginTop: 16 }}
        title={'Période de TMA'}
        subtitle={'C’est le moment d’apporter des travaux modificatifs à votre logement, si vous le souhaitez.'
        }
        buttonAlert={
            startDate['1']
            ? undefined
            : {
                title: 'Faire une demande',
                link: `/tma/${residenceId}`,
              }
        }
        button={{
          title: 'Voir mes demandes',
          link: `/tma/${residenceId}`,
        }}
        active={startDate['1']}
        over={startDate['1'] || maxWorkflow > 2} //FIXME : Unknown condition for when TMA is Over => Deadline TMA Date ?
      />
	  )}
      {/** Worflow line ... */}
        {/* <WorkflowItem
        title={'Réservation'}
        icon={'_tr-reservation'}
        startDate={startDate['2']}
        endDate={endDate['2']}
        active={startDate['2']}
      /> */}
      <WorkflowItem
        title={'Achèvement des fondations'}
        icon={'_tr-fondations'}
        startDate={startDate['4']}
        endDate={endDate['4']}
        active={startDate['4']}
      />
      <WorkflowItem
        title={'Fin des gros oeuvres'}
        icon={'_tr-fin-gros-oeuvre'}
        startDate={startDate['5']}
        endDate={endDate['5']}
        active={startDate['5']}
      />
      <WorkflowItem
        title={'Mise hors d\'eau'}
        icon={'_tr-mise-hors-d-eau'}
        startDate={startDate['15']}
        endDate={endDate['15']}
        active={startDate['15']}
      />
      <WorkflowItem
        title={'Mise hors d\'air'}
        icon={'_tr-mise-hors-d-air'}
        startDate={startDate['16']}
        endDate={endDate['16']}
        active={startDate['16']}
      />
	  {shouldShowTravauxWorkflowCard6 && (
      <TravauxWorkflowCard
        style={{ marginTop: 2 }}
        title={'Choix des prestations'}
        subtitle={
          'Choisissez votre décoration : vos sols, vos matières, vos couleurs…'
        }
        button={{
          title: 'Voir le catalogue',
          link: `/prestations/${residenceId}`,
        }}
        active={startDate['6']}
        over={lot?.choicePrestationsDone || maxWorkflow > 7} // FIXME : unknown info ??
      />
	  )}
      <WorkflowItem
        title={'Achèvement des cloisons'}
        icon={'_tr-fin-cloisons'}
        startDate={startDate['7']}
        endDate={endDate['7']}
        active={startDate['7']}
      />
      <WorkflowItem
          title={'Visite des cloisons achevées'}
          icon={'_tr-fin-cloisons'}
          startDate={startDate['8']}
          endDate={endDate['8']}
          active={startDate['8']}
      />
      <WorkflowItem
          title={'Réunion d\'information pré-AG'}
          icon={'_tr-reunion'}
          startDate={startDate['18']}
          endDate={endDate['18']}
          active={startDate['18']}
      />
      <WorkflowItem
          title={'Visite de pré-livraison'}
          icon={'_tr-pre-livraison'}
          startDate={startDate['10']}
          endDate={endDate['10']}
          active={startDate['10']}
      />
      <WorkflowItem
          title={'1ère Assemblée Générale de copropriété'}
          icon={'_tr-reunion'}
          startDate={startDate['17']}
          endDate={endDate['17']}
          active={startDate['17']}
      />
      <WorkflowItem
        title={'Achèvement des travaux'}
        icon={'_tr-fin-travaux'}
        startDate={startDate['9']}
        endDate={endDate['9']}
        active={startDate['9']}
      />
      <WorkflowItem
        title={'Livraison'}
        icon={'_tr-livraison'}
        startDate={startDate['11']}
        endDate={endDate['11']}
        active={startDate['11']}
      />
      <WorkflowItem
          title={'Levée des réserves'}
          icon={'_tr-levee-reserves'}
          startDate={startDate['12']}
          endDate={endDate['12']}
          active={startDate['12']}
      />
      <WorkflowItem
        title={'Service après-vente'}
        icon={'_tr-sav'}
        startDate={startDate['13']}
        endDate={endDate['13']}
        active={startDate['13']}
      />







        {/*<TravauxWorkflowCard
        style={{ marginTop: 2, marginBottom: 24 }}
        title={'SAV et garanties'}
        alert={"Jusqu'à 10 ans"}
        button={{
          title: 'Faire une demande',
          link: `mailto:${lot?.contacts?.sav?.email}`,
        }}
        active={startDate['13']}
        over={lot?.program?.choiceDate} // FIXME : Unknown logic and info ??
      />
      <TravauxCard chantier={true} /> */}
    </>
  );
};

export { Travaux };
