export const getNotifications = ({ lot, program, appel }) => {
  let data;
  let pushNotif = [];
  if (appel) {
    data = { ...appel };
    data.call = Object.values(data).filter(
      (v) => v['4'] !== null || v['4'] > 0 || v['4'] !== '0' // 4 correspond au montant appelé, si > 0 il faut payer !
    );
    if (data.call.length > 0) pushNotif.push({ id: 2, active: true });
  }
  if (program?.workflowTypeId > 0) {
    if (lot.documents) {
      // On trie les docs uploadés, si upload on considère que le client a fait la demande

      /** TMA  */
      const uploadTMA = lot.documents.filter(
        (doc) => doc.name.substr(0, 7) === 'upload_' || doc.objectsTypeId === 8
      );
      if (
        uploadTMA.length > 0 &&
        program?.workflowTypeId > 3 &&
        program.workflowTypeId < 5 //FIXME
      )
        pushNotif.push({ id: 3, active: false });
      if (uploadTMA.length === 0 && program.workflowTypeId === 1)
        pushNotif.push({ id: 3, active: true });

      /** Prestations **/
      const uploadPrestations = lot.documents.filter(
        (doc) => doc.name.substr(0, 7) === 'upload_' || doc.objectsTypeId === 6
      );
      if (uploadPrestations.length > 0) //FIXME
        pushNotif.push({ id: 1, active: false });
      if (uploadPrestations.length === 0 && program.workflowTypeId === 6)
        pushNotif.push({ id: 1, active: true });
    }
    if (program.workflowTypeId === 8) pushNotif.push({ id: 5, active: true }); //FIXME condition
    if (program.workflowTypeId === 10) pushNotif.push({ id: 4, active: true }); //FIXME condition
  }
  return pushNotif;
};

/***************  id notifs 
    id: 1,
    title: 'Choix des prestations',
    activeSubtitle: 'Il est temps que vous choisissiez votre intérieur.',

    id: 2,
    title: 'Appels de fonds',
    activeSubtitle: 'Une nouvelle échéance approche.',

    id: 3,
    title: 'Vos modifications',
    activeSubtitle: 'Il est temps de demander vos modifications', //FIXME : unknown text

    id: 4,
    title: 'Félicitations',
    activeSubtitle: "Il est temps d'aller voir votre bien !", //FIXME : unknown text

    id: 5,
    title: 'Visite de cloisons achevées', //FIXME : Faute d'orthographe + pas logique avec le texte...
    activeSubtitle: 'Vous devez choisir votre date de visite',
    *********************************/
